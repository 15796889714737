<template>
  <ion-modal
    :initial-breakpoint="0.7"
    :is-open="toggleEditPayment"
    :backdropDismiss="true"
    @didDismiss="$emit('closeModal')"
    class="modal-radius-md"
  >
    <div>
      <ion-grid>
        <ion-row class="ion-align-items-center mb-4 border-bottom">
          <ion-col>
            <ion-label class="fw-600 fs-3 text-gray-700">{{ $t('edit_payment_amount') }} </ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-icon
              @click="$emit('closeModal')"
              size="large"
              class="f-icon"
              :icon="closeCircleOutline"
            ></ion-icon>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-text class="fw-600 text-gray-700 text-capitalize">{{ $t('sub_total') }} </ion-text>
          </ion-col>
          <ion-col size="auto">
            <ion-text class="text green fw-500"> {{ formatPrice(subTotalAmount) }}</ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-text class="fw-600 text-gray-700">{{ $t('amount_due') }} </ion-text>
          </ion-col>
          <ion-col size="auto">
            <ion-text class="green fw-500"> {{ formatPrice(overdueAmount) }}</ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-text class="fw-600 text-gray-700">{{ $t('overpayment') }} </ion-text>
          </ion-col>
          <ion-col size="auto">
            <ion-text class="text green fw-500"> {{ formatPrice(overpaymentAmount) }} </ion-text>
          </ion-col>
        </ion-row>
        <div class="spacer"></div>

        <ion-row class="mt-4">
          <ion-col>
            <ion-text class="fw-600 text-gray-700">{{ $t('total_payment') }} </ion-text>
          </ion-col>
          <ion-col size="auto">
            <ion-text class="text green fw-500"> {{ formatPrice(paidAmount) }} </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>

      <div class="d-flex align-center py-2 input">
        <ion-text class="pr-1 fw-500 text-gray-700">{{ currencySymbol }}</ion-text>
        <ion-input
          class="fw-500 text-gray-700"
          :clear-input="paidAmount ? true : false"
          placeholder="0.00"
          type="number"
          v-model="paidAmount"
          name="amount"
          inputmode="decimal"
          @ionInput="onInputAmount($event)"
        ></ion-input>
      </div>

      <div class="d-flex align-center justify-center mt-5">
        <ion-button color="primary" fill="solid" @click="getPaymentAmount">
          <ion-label>{{ $t('update_amount') }}</ion-label>
        </ion-button>
      </div>
    </div>
  </ion-modal>
</template>
<script>
import { toastError } from '@/modules/shared/utils';
import { priceFormatter } from '@/utils/';
import { closeCircleOutline } from 'ionicons/icons';
import { defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'ModalEditPayment',
  emits: ['closeModal', 'onEditPaidAmount'],
  props: {
    toggleEditPayment: {
      type: Boolean,
      default: false
    },
    totalOutstanding: {
      type: Number,
      default: 0
    },
    totalPaidAmount: {
      type: Number,
      default: 0
    },
    minimumOutstandingAmount: {
      type: Number,
      default: 0
    },
    currencySymbol: {
      type: String,
      default: 'S$'
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const subTotalAmount = ref(props.totalOutstanding > 0 ? props.totalOutstanding : 0);
    const paidAmount = ref(props.totalPaidAmount > 0 ? props.totalPaidAmount : 0);
    const overdueAmount = ref(0);
    const overpaymentAmount = ref(0);
    const formatPrice = (value) => priceFormatter(props.currencySymbol, value);
    const calculate = (value) => {
      paidAmount.value = value;
      const overpayment = +value - +subTotalAmount.value;
      if (overpayment > 0) {
        overdueAmount.value = 0;
        overpaymentAmount.value = overpayment;
      } else if (overpayment <= 0) {
        overpaymentAmount.value = 0;
        overdueAmount.value = Math.abs(overpayment);
      }
    };
    const onInputAmount = (event) => {
      const sanitizedValue = event.target.value.replace(/[^0-9.,]/g, '');
      const parts = sanitizedValue.split(/[.,]/);
      if (parts.length > 1) {
        const integerPart = parts[0];
        const decimalPart = parts[1].substring(0, 2);
        event.target.value = `${integerPart}.${decimalPart}`;
      } else if (parts.length === 1 && parts[0] !== '0') {
        event.target.value = sanitizedValue;
      } else {
        event.target.value = '';
      }
    };
    watch(paidAmount, (value) => calculate(value));
    return {
      onInputAmount,
      closeCircleOutline,
      subTotalAmount,
      paidAmount,
      overdueAmount,
      overpaymentAmount,
      formatPrice,
      getPaymentAmount: () => {
        if (+overdueAmount.value.toFixed(2) >= props.minimumOutstandingAmount) {
          toastError(`${t('amount_due_error')} ${props.minimumOutstandingAmount}`);
          return;
        }
        emit('onEditPaidAmount', paidAmount.value, overpaymentAmount.value, overdueAmount.value);
        paidAmount.value >= 0 ? emit('closeModal') : '';
      }
    };
  }
});
</script>
<style lang="scss" scoped>
.text-capitalize {
  text-transform: capitalize !important;
}
ion-text {
  text-transform: capitalize !important;
}
.line {
  width: 100%;
  margin: 0.7rem 0 1rem 0;
  height: 6px;
  position: absolute;
  left: 0;
  right: 0;
  background: #eeeded;
}
.f-icon {
  font-size: 26px;
  color: gray;
}
.border-bottom {
  border-bottom: 1px solid var(--ion-color-text-gray-300);
}
.green {
  color: #039855;
}
ion-button {
  --border-radius: 10px;
  width: 95%;
  height: 44px;
}
.input {
  margin: 0 auto;
  height: 32px;
  width: 90%;
  border-bottom: 1px solid rgb(153, 153, 153);
}
</style>
